var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-list-wrapper mt-3 ml-3 mr-7" },
    [
      _vm.loading
        ? _c("v-skeleton-loader", { attrs: { type: " article,article" } })
        : _vm._l(_vm.listItems, function (item, key) {
            return _c("app-list-item", {
              key: key,
              attrs: { item: item, actions: _vm.actions },
            })
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }