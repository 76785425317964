<template>
  <div class="app-list-wrapper mt-3 ml-3 mr-7">
    <v-skeleton-loader v-if="loading" type=" article,article"></v-skeleton-loader>
    <app-list-item v-else v-for="(item, key) in listItems" :key="key" :item="item" :actions="actions" />
  </div>
</template>

<script>
import AppListItem from '@/components/Common/AppListItem';

export default {
  name: 'AppList',
  components: {
    AppListItem,
  },
  props: {
    listItems: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    actions: {
      type: Object,
    },
  },
};
</script>
