var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "list-item-wrapper mb-1",
      attrs: { align: "center", justify: "space-between" },
    },
    [
      _vm.item.text
        ? _c(
            "v-col",
            { staticClass: "small-text standard d-flex align-center" },
            [
              _c("span", { staticClass: "app-list-item-title" }, [
                _vm._v(_vm._s(_vm.item.text)),
              ]),
              _vm.item.textStatus
                ? _c("span", { staticClass: "ml-2 text-cyan" }, [
                    _vm._v(_vm._s(_vm.item.textStatus)),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm.item.broadcasts
        ? _c("v-col", { staticClass: "small-text thin shadow" }, [
            _vm._v(
              _vm._s(
                _vm._f("broadcastList")(
                  _vm.item.broadcasts.values,
                  _vm.$t("broadcasts.destinations")
                )
              )
            ),
          ])
        : _vm._e(),
      _vm.item.createdDate
        ? _c("v-col", { staticClass: "small-text thin shadow" }, [
            _vm._v(
              _vm._s(_vm.$t("broadcasts.created")) +
                ": " +
                _vm._s(_vm._f("formatDate")(_vm.item.createdDate))
            ),
          ])
        : _vm._e(),
      _vm.item.type === "destination"
        ? _c(
            "v-col",
            { staticClass: "list-item-destination-wrapper py-2" },
            [
              _c("AppAvatar", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.item.avatar,
                    expression: "item.avatar",
                  },
                ],
                staticClass: "ml-2",
                attrs: { src: _vm.item.avatar, alt: _vm.item.user },
              }),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column destination-content",
                  class: { "ml-3": _vm.item.avatar },
                },
                [
                  _c("span", { staticClass: "user pb-1" }, [
                    _vm._v(" " + _vm._s(_vm.item.user) + " "),
                  ]),
                  _c("span", { staticClass: "channel" }, [
                    _vm._v(" " + _vm._s(_vm.item.channel) + " "),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.item.tokenIsValid === false
        ? _c("v-col", { staticClass: "small-text thin shadow warning-text" }, [
            _vm._v(_vm._s(_vm.$t("broadcasts.yourToukenIsExpired"))),
          ])
        : _vm._e(),
      _vm.actions
        ? _c(
            "v-col",
            {
              staticClass:
                "small-text standard action d-flex justify-end align-center",
            },
            [
              _c(
                "span",
                {
                  staticClass: "cursor-pointer",
                  attrs: { "data-test-id": "dashboard-enter-studio" },
                  on: {
                    click: function ($event) {
                      return _vm.actions.onTextClick(_vm.item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.actions.text) + " ")]
              ),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "more-actions",
                                    attrs: { icon: "" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("AppIcon", {
                                  staticClass: "ml-1",
                                  attrs: { size: "16", icon: "more-vertical" },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1239667873
                  ),
                },
                [
                  _vm._l(_vm.actions.childs, function (child, index) {
                    return _c(
                      "v-list",
                      { key: index, staticClass: "app-action-list py-0" },
                      [
                        child.type !== "divider" && !child.hide
                          ? _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return child.onClick(_vm.item)
                                  },
                                },
                              },
                              [
                                child.type !== "divider"
                                  ? _c(
                                      "v-list-item-content",
                                      {
                                        attrs: {
                                          "data-test-id": `dashboard-${child.dataSetId}`,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex align-center",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "list-item-icon mr-2",
                                              },
                                              [
                                                child.icon
                                                  ? _c("AppIcon", {
                                                      attrs: {
                                                        icon: child.icon,
                                                        width: "20",
                                                        height: "20",
                                                        color: child.color
                                                          ? child.color
                                                          : "#ffffff",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                child.image
                                                  ? _c("img", {
                                                      attrs: {
                                                        src: `/images/${child.image}`,
                                                        alt: "",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "list-item-text",
                                                style: {
                                                  color: child.color
                                                    ? child.color
                                                    : "#ffffff",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(child.title) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : child.type === "divider" && !child.hide
                          ? _c("v-divider", { staticClass: "opacity-50" })
                          : _vm._e(),
                        _vm.viewOnItems &&
                        _vm.deleteItem &&
                        index === _vm.actions.childs.length - 1
                          ? _c("v-divider", { staticClass: "opacity-50" })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm.viewOnItems.length
                    ? _c(
                        "div",
                        _vm._l(_vm.viewOnItems, function (item, key) {
                          return _c(
                            "v-list",
                            {
                              key: key,
                              staticClass: "view-on-items app-action-list py-0",
                            },
                            [
                              _c(
                                "v-list-item",
                                { staticClass: "cursor-pointer" },
                                [
                                  _c("v-list-item-content", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "list-item-icon mr-2",
                                          },
                                          [
                                            _c("ProviderIcon", {
                                              class: {
                                                "youtube-list-item-image":
                                                  item.providerShortCode ===
                                                  "Youtube",
                                              },
                                              attrs: {
                                                provider:
                                                  item.providerShortCode,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "list-item-text view-on-text",
                                            attrs: {
                                              href: item.viewUrl,
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("viewOn", {
                                                  channel: item.title,
                                                })
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }