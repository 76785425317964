<template>
  <v-row class="list-item-wrapper mb-1" align="center" justify="space-between">
    <v-col v-if="item.text" class="small-text standard d-flex align-center">
      <span class="app-list-item-title">{{ item.text }}</span>

      <span v-if="item.textStatus" class="ml-2 text-cyan">{{ item.textStatus }}</span>
    </v-col>

    <v-col v-if="item.broadcasts" class="small-text thin shadow">{{ item.broadcasts.values | broadcastList($t('broadcasts.destinations')) }}</v-col>
    <v-col v-if="item.createdDate" class="small-text thin shadow">{{ $t('broadcasts.created') }}: {{ item.createdDate | formatDate }}</v-col>

    <v-col v-if="item.type === 'destination'" class="list-item-destination-wrapper py-2">
      <AppAvatar v-show="item.avatar" :src="item.avatar" :alt="item.user" class="ml-2" />

      <div class="d-flex flex-column destination-content" :class="{ 'ml-3': item.avatar }">
        <span class="user pb-1">
          {{ item.user }}
        </span>
        <span class="channel">
          {{ item.channel }}
        </span>
      </div>
    </v-col>

    <v-col v-if="item.tokenIsValid === false" class="small-text thin shadow warning-text">{{ $t('broadcasts.yourToukenIsExpired') }}</v-col>

    <v-col v-if="actions" class="small-text standard action d-flex justify-end align-center">
      <span data-test-id="dashboard-enter-studio" @click="actions.onTextClick(item)" class="cursor-pointer">
        {{ actions.text }}
      </span>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="more-actions" v-bind="attrs" v-on="on">
            <AppIcon class="ml-1" size="16" icon="more-vertical" />
          </v-btn>
        </template>
        <v-list class="app-action-list py-0" v-for="(child, index) in actions.childs" :key="index">
          <v-list-item v-if="child.type !== 'divider' && !child.hide" @click="child.onClick(item)">
            <v-list-item-content v-if="child.type !== 'divider'" :data-test-id="`dashboard-${child.dataSetId}`">
              <div class="d-flex align-center">
                <span class="list-item-icon mr-2">
                  <AppIcon v-if="child.icon" :icon="child.icon" width="20" height="20" :color="child.color ? child.color : '#ffffff'" />
                  <img v-if="child.image" :src="`/images/${child.image}`" alt="" />
                </span>
                <span class="list-item-text" :style="{ color: child.color ? child.color : '#ffffff' }">
                  {{ child.title }}
                </span>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-else-if="child.type === 'divider' && !child.hide" class="opacity-50"></v-divider>
          <v-divider v-if="viewOnItems && deleteItem && index === actions.childs.length - 1" class="opacity-50"></v-divider>
        </v-list>
        <div v-if="viewOnItems.length">
          <v-list class="view-on-items app-action-list py-0" v-for="(item, key) in viewOnItems" :key="key">
            <v-list-item class="cursor-pointer">
              <v-list-item-content>
                <div class="d-flex align-center">
                  <span class="list-item-icon mr-2">
                    <ProviderIcon :provider="item.providerShortCode" :class="{ 'youtube-list-item-image': item.providerShortCode === 'Youtube' }" />
                  </span>
                  <a :href="item.viewUrl" target="_blank" class="list-item-text view-on-text">{{ $t('viewOn', { channel: item.title }) }}</a>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import { BROADCAST_FILTERS } from '@/constants/enums';

export default {
  name: 'AppListItem',
  filters: {
    broadcastList(value, text) {
      if (!value.length) {
        return '';
      } else if (value.length === 1) {
        return `${value[0]} only`;
      } else if (value.length < 4) {
        return value.join(',');
      } else {
        return `${value.length} ${text}`;
      }
    },
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    actions: {
      type: Object,
    },
  },
  computed: {
    viewOnItems() {
      if (this.item?.raw?.items && this.item?.raw?.status !== BROADCAST_FILTERS.PAST) {
        return this.item.raw.items.map((item) => {
          const { title, viewUrl, providerShortCode } = item;
          return {
            title: this.item.raw.items.filter((providerItem) => providerItem.providerShortCode === providerShortCode).length > 1 ? title : providerShortCode,
            viewUrl,
            providerShortCode,
          };
        });
      } else {
        return [];
      }
    },
    deleteItem() {
      return Boolean(this.actions?.childs && this.actions.childs.some((child) => child.type === 'delete'));
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  border-radius: 12px;
  .app-action-list {
    border-radius: 0 !important;
    &.view-on-items {
      &:hover {
        background: #3c3c3c;
      }
    }
    .list-item-icon {
      min-width: 24px;
    }
    .list-item-text {
      font-size: 13px;
      line-height: 11px;
      &.view-on-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 14em;
        color: var(--v-white-base);
      }
    }
  }
}

.list-item-wrapper {
  background: var(--v-night-rider-base);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  .app-list-item-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 10em;
  }
  .small-text {
    font-size: 11px;
    &.standard {
      font-weight: 600;
    }
    &.thin {
      font-weight: 500;
    }
    &.shadow {
      color: var(--v-dark-gray-base);
    }
    &.action {
      color: var(--v-cyan-base);
    }
  }
  .more-actions {
    text-decoration: none;
  }

  .list-item-destination-wrapper {
    display: flex;
    align-items: center;
    .avatar-image {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
    .destination-content {
      .user {
        font-size: 11px;
        line-height: 13px;
        color: var(--v-white-base);
        opacity: 0.5;
      }
      .channel {
        font-size: 9px;
        line-height: 11px;
        color: var(--v-white-base);
        opacity: 0.5;
      }
    }
  }
  .text-cyan {
    color: var(--v-cyan-base);
  }
}
.youtube-list-item-image {
  width: 24px;
}
</style>

